.container {
  /* columns: 2 ; */
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
}
.member_box {
  /* border-radius: 10px; */
  padding-left: 0.5rem;
  /* border: 2px solid black; */
  margin: auto;
  width: 25rem;
  height: 175px;
}

.member_info {
  margin-top: auto;
  margin-bottom: auto;
}

.member_info_about {
  margin-top: auto;
  margin-bottom: auto;
}

.member_info_head {
  margin-top: auto;
  margin-bottom: auto;
}

/* member about */

.member_about {
  /* border-radius: 10px; */
  padding-left: 0.5rem;
  /* border: 2px solid black; */
  margin: auto;
  width: 18rem;
  height: 150px;
}

.member_info_about {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.img_member_about {
  width: 80px !important;
  height: 80px !important;
}

@media only screen and (max-width: 420px) {
  .member_about {
    width: 16rem !important;
  }
  .img_member_about {
    width: 70px !important;
    height: 70px !important;
  }
  .member_info_about {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  .member_info_head {
    font-size: 1.2rem !important;
    margin: auto !important;
  }
  .about_cont {
    font-size: 10px !important;
  }
}

/* end member about */

.about_cont {
  margin-top: auto;
  margin-bottom: auto;
}

.member_cont {
  width: 100px !important;
  height: 25px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  border-radius: inherit !important;
  background: none !important;
  box-shadow: none !important;
  min-height: inherit !important;
  margin-bottom: 2px !important;
  justify-content: inherit !important;
}

.view_detail {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 25px;
  cursor: pointer;
}

.view_detail_btn {
  text-decoration: none !important;
  color: black !important;
  font-weight: bold;
}

.img_member {
  width: 100px !important;
  height: 100px !important;
}

#border_bottom_1 {
  border-bottom: 3px solid darkslategrey;
  left: 25px;
  right: 0%;
  width: 100px;
  position: relative;
}

.member_card {
  height: 100%;
}

.member_details {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
}

.apply_membership_button {
  margin-top: 25px;
}

@media only screen and (max-width: 420px) {
  .member_box {
    width: 90% !important;
    height: 12rem !important;
    padding-bottom: 0.7rem !important;
  }
  .img_member {
    width: 80px !important;
    height: 80px !important;
  }
  .apply_membership_button {
    margin-top: 0px;
    margin-right: 2%;
  }
}

@media only screen and (max-width: 320px) {
  .member_info_name {
    font-size: 1.3rem !important;
  }
  .member_info_desig {
    font-size: 1.1rem !important;
  }
  .member_cont1 {
    font-size: 10px;
  }
}
