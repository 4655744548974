/* @media only screen and (max-width: 420px) {
    div{
      width: 28rem;
    }
  } */


.NavbarBottom{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 0.4rem;
}

.NavbarButtons{
    width: 240px;
    height: 30px;
    background-color: #454545;
    color: white;
    font-weight: bolder;
    cursor: pointer;
}