.heading_main {
  background-image: linear-gradient(to left, blue, violet, pink, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: 800;
}

.middle {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 30px;
}
.mainDiv {
  background-color: "black";
  color: white;
  font-size: 20px;
  height: 65vh;
  margin-right: 100px;
  margin-left: 100px;
}
.heading {
  padding-top: 30px;
}
#highlight {
  color: cyan !important;
  font-size: 40px !important;
  font-weight: 600;
  letter-spacing: 2px;
}
.hr1 {
  margin-top: 10px;
  border-top: 5px solid #7fffd4;
  border-radius: 5px;
  width: 60%;
  margin-left: 20%;
}
.hr2 {
  width: 200px;
  border-top: 40px solid #7fffd4;
  border-radius: 0px 5px 5px 0px;
  margin-bottom: 1px;
}
.hr3 {
  width: 300px;
  border-radius: 0px 5px 5px 0px;
  border-top: 40px solid #00ffff;
}

#profile {
  margin-top: 0px;
  width: 200px !important;
  height: 200px !important;
  border-radius: 50%;
}
#join {
  padding-top: 20px;
  margin-top: 10px;
  letter-spacing: 1px;
}
.bottom {
  background-color: black;
  padding-top: 10px;
}
.foot {
  margin-top: 15px;
  background-color: "black";
}
@media screen and (max-width: 786px) {
  .mainDiv {
    height: 95vh;
    margin-right: 10px;
    margin-left: 10px;
  }
  .heading_main {
    font-size: 40px;
  }
}
