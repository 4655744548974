.navbar_item {
  color: white !important;
  text-decoration: none;
}
.loginBtn{
  color: #D2EFF1;
  width: 100px;
  height: 50px;
  border-radius: 15px;
  background-color: #221857;
}

.navbar_member{
    color: white !important;
}

.MuiAppBar-positionStatic {
  position: fixed !important;
  top: 0% !important;
  margin-bottom: 20px;
}

#border_bottom {
  border-bottom: 3px solid #52efda;
  left: 10%;
  right: 10%;
  position: absolute;
}

#border_bottom1 {
  border-bottom: 3px solid #52efda;
  left: 1.5%;
  right: 1.5%;
  position: absolute;
}

.navbar_member:hover #border_bottom {
  border-bottom: 4px solid #8dffcc;
}

.navbar_member:hover #border_bottom1 {
  border-bottom: 4px solid #8dffcc;
}

.icons {
  padding: inherit !important;
  margin: 15px !important;
}

.icons:hover {
  background-color: inherit !important;
}

.account_icon {
  color: #52efda !important;
}

.account_icon:hover {
  transform: scale(1.1);
  color: #8dffcc !important;
}



.image_logo {
  width: 280px;
  height: 50px;
}

@media only screen and (max-width: 280px) {
  .image_logo {
    width: 200px;
    height: 50px;
  }
}

.log_btn {
  min-width: 100px;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  font-size: 20px;
  font-family: "Courier New", Courier, monospace;
  border-radius: inherit !important;
  border: inherit !important;
}

/*  BOTTOM APPBAR */

.bottom_icon {
  color: white !important;
  font-size: 32px !important;
  padding: 4px;
}

.fab_button {
  color: black;
}

.bottom_icon:hover {
  background-color: #52efda !important;
  color: black !important;
  border-radius: 20px;
  font-size: 32px !important;
}
.MuiIconButton-root{
  border-radius: 0px;
}

@media only screen and (max-width: 320px) {
  .bottom_icon {
    color: white !important;
    font-size: 26px !important;
    padding: 4px;
  }
  .bottom_icon:hover {
    background-color: #52efda !important;
    color: black !important;
    border-radius: 20px;
    font-size: 26px !important;
  }
}
