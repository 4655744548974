.Membership_form {
  font-family: "Open Sans", sans-serif !important;
  /* border:1px solid blue; */
  width:80%;
  margin:auto;
  margin-bottom: 5rem;
  margin-top: 2rem; 
} 

.form_div{
  margin:auto; 
  width:60%;
  /* border: 1px solid black; */
  padding:10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #befaf2;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.member_ship {
  width: 80%;
  margin: auto;
  /* border: 1px solid red; */
  padding:20px;
}

.member_ship > h2 {
  text-align: center;
}

.buttons {
  cursor: pointer !important;
  /* margin-left: 25% !important;
  margin-right: 20% !important; */
  border:none;
  outline:none;
  margin: auto;
}

.btn {
  width: 100%;
  height: 34px;
  background-color: rgb(82, 239, 218);
  font-weight: 900;
  font-stretch: ultra-expanded;
  cursor: pointer;
}

.apply_btn{
  display: flex;
  padding:20px;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  margin: auto;
}

/* .Box {
  margin-top: 4rem;
} */

/* Event Creation Form */
.fullpage{
  width:100%; 
  overflow-x: hidden; 
}
.Event_form {
  margin-bottom: 2rem;
  /* background-color: red; */
  /* overflow-y: hidden; */
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  /* border:2px solid red;  */
}

.subscription_button_container {
  /* background-color: yellow; */
  width: 25%;
  align-self: flex-end;
  /* margin-right: 5%; */
}

.subscription_btn {
  width: 80%;
  height: 40px;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  border: none;
  font-weight: 900;
  font-size: 1.2rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
  padding: 0px 20px;
}

.form_event {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: #befaf2;
  margin-top: 30px !important;
  margin: auto;
  width: 30rem !important;
  /* height: 12rem !important; */
  height:auto;
  padding-bottom: 5px;
  padding-left: 5px !important;
  border: 2px solid black;
}

.message {
  margin-top: auto !important;
  margin-bottom: auto !important;
  color: green;
  text-align: center !important;
  font-weight: 600;
}

.message2 {
  margin-top: auto !important;
  margin-bottom: auto !important;
  text-align: center !important;
  font-weight: 600;
}

.form_event_2 {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: #befaf2;
  margin-top: 30px !important;
  margin: auto;
  width: 30rem !important;
  height: auto;
  padding-bottom: 5px;
  padding-left: 5px !important;
  border: 2px solid black;
}
@media only screen and (max-width: 800px) {
  .subscription_btn {
    width: 80% !important;
    font-size: 1rem !important;
    padding: 0px 5px !important;
  }
  .Membership_form{
    width: 90%;
    margin-bottom: 6rem;
  }
  .form_div{
    width:90%;
  }
}

@media only screen and (max-width: 600px) {
  .subscription_btn {
    width: 80% !important;
    font-size: 0.8rem !important;
    padding: 0px 5px !important;
  }
  
}

@media only screen and (max-width: 500px) {
  .form_event {
    width: 20rem !important;
  }

  .form_event_2 {
    width: 20rem !important;
  }
  .subscription_btn {
    width: 90% !important;
    font-size: 0.6rem !important;
    padding: 0px 2px !important;
  }
  .form_div{
    width:100%;
  }
}

/* @media only screen and (max-width: 320px){
    .subscription_btn{
        font-size: 0.5rem!important;
        padding: 0px 5px!important;
    }
} */

/* Event Creation Form */

/* .Box_type {
  margin-bottom: 6rem !important;
} */

.member_box {
  margin-bottom: 20px !important;
}

@media only screen and (max-width: 420px) {
  .Membership_form{
    margin-top: 1rem;
    margin-bottom: 6rem;
  }
  .member_ship{
    width: 100%;
  }
  .apply_head {
    font-size: 1.7rem;
  }
}

/* popup css */
.simple-modal-desc {
  font-size: 12px !important;
  font-weight: 550 !important;
}

/* .Box_type_event{
    margin-bottom: 5rem!important;
    margin-top: 4rem!important;
}

@media only screen and (min-width: 420px){
    .Box_type_event{
        width: 25rem!important;
        margin-bottom: inherit!important;
        margin-top: 5rem!important;
    }
} */
