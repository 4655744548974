.profile_full_page {
    /* background-color: white; */
    height: 120vh;
    background-color: #fff !important;
}
.userBox{
    margin-top: 30px;
    display: flex; 
    /* border : 2px solid black ; */
    height: 115vh;
}
.containerBox {
  width: 50%;
  height: 100vh; 
  /* border : 2px solid black ; */
  display: block;
  margin: auto;
  margin-top: 20px;
  margin-left: 20%;
  padding: 20px;
  position: relative; 
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.msg_head {
    color : green; 
    font-size: 1rem;
    margin: 8px;
    float: right;
    margin-top: 15px;
}
.profile_div {
    width: 100%;
    display: flex;
    padding : 5px; 
    /* border : 1px solid black; */
    height:auto;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
.img_upload_div {
    padding: 10px;
    /* border: 1px solid black; */
    width: 50%;
}

.profile_img_box {
    width: 300px ;
    /* border: 1px solid black;  */
}

.profile_img{
    margin-bottom: 10px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 1px solid black;
}

.fab_add {
  background: black !important;
  /* box-shadow: none!important; */
  width: 30px !important;
  height: 25px !important;
}

.fab_add_icon {
    color: white !important;
}

.submit_pic {
    width: auto;
    height: 30px;
    background-color: #8dffcc;
    font-size: 18px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.2s;
    outline: none;
}
.submit_pic:hover {
    height: 30px;
    box-shadow: rgba(56, 32, 32, 0.35) 0px 5px 15px;
}  
.paper_plane{
    width: 15px; 
    height: 15px;
}
.userdetails_div {
    float: right; 
    /* border : 1px solid black;  */
    width : 100%; 
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding : 10px;
}

.username {
    font-size: 3vw;
    font-weight: bold; 
    /* border: 1px solid black;  */
    margin-top: 0px;
}

.profile_bio_div {
    /* border : 1px solid black;  */
    /* border: 1px solid black; */
    
    float: right;
    text-align: justify;
}

.user_points {
    /* border : 1px solid black;  */
    margin-top: 0px;
    font-size: 1.5vw;
}

.user_profile_div {
    /* border : 1px solid black;  */
    position: absolute;
    display: block;
    width: 96%;
    height: 45vh;
    padding-top: 20px;
    padding-bottom: 20px;
    /* box-shadow: rgba(56, 32, 32, 0.35) 0px 5px 15px; */
}

/*If the h5 has to be put left aligned then following code will be applied*/
/* .user_profile_div2{
    margin-left: 20%;
}
.user_profile_div h5 {
    text-align: justify;
} */

.button_div {
    /* border: 1px solid black; */
    position:absolute; 
    width: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.btn_below_link{
    /* border:1px solid black; */
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
    width:40%;
    outline: none;
}

.btn_below_profile {
    width: 100%; 
    margin:10px;
    padding: 15px;
    font-size: 1.5vw;
    font-family: "Spartan", sans-serif !important;
    background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%);
    /* background-color: #8dffcc; */
    border: none;
    cursor: pointer;
    outline:none;
    transition: 0.5s;
}

.btn_below_profile:hover {
    background-color: white;
    color: black;
    /* border : 0.5px solid gray; */
    box-shadow:rgba(56, 32, 32, 0.35) 0px 5px 15px;
}
.side_div{
    float: right;
    width: 25%; 
    height: auto; 
    margin-top: 20px;
    /* border: 1px solid black; */
}
.toogle_btn_div {
   display: flex;
   outline: none;
   justify-content: end;
   /* border: 1px solid black; */
}
.toogle_btn {
    padding: 10px; 
    margin: 10px;
    cursor: pointer; 
    background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%);
    border:none;
    outline: none;
}
.toogle_btn_div :hover {
    box-shadow:rgba(56, 32, 32, 0.35) 0px 5px 15px;

}
.sidebar_div{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:auto;
    padding: 10px;
    /* border: 1px solid black;  */
}

.sidebar_btn_div{
    padding: 10px; 
    width: 60%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px; 
}

.sidebar_button {
    padding: 15px;
    width: 100%;
    font-family: "Spartan", sans-serif !important;
    background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%);
    border : none; 
    font-size: 1vw;
    outline: none;
    cursor: pointer;
}
.sidebar_button:hover {
    box-shadow:rgba(56, 32, 32, 0.35) 0px 5px 15px;
}

.btn_drawer{
    display: none;
}
.drawer_div {
    display: none;
}
.sidebar_btn_link{
    width:100%; 
    display: flex;
    justify-content: center;
    text-decoration: none;
}


/* If the browser window is 900px or smaller*/
@media only screen and (max-width: 900px){
    .containerBox {
        width: 80%;
    }
    .profile_div{
        width: 100%; 
    }
    .user_profile_div{
        width: 95%; 
    }
    .sidebar_btn_div{  
        width:90%;
        padding:0;
    }
    .sidebar_btn_link{
        margin-top: 5px;
        width: 100%;
    }
    .sidebar_button{
        font-size: 1.5vw;
    }
}
/* If the browser window is 600px or smaller*/
@media only screen and (max-width: 600px)  {
    .profile_full_page{
        height: 110vh;
        background-color: #fff !important;
    }
    .userBox{
        height: 110vh;
        margin-bottom: 150px;
    }
    .containerBox{
        width: 90%;
        margin: auto;
        margin-top: 20px;
        height: 90vh;
    }
    .profile_div {
        /* margin: auto; */
        width: 100%;
    }
    .side_div{
        display: none;
    }
    .username{
        font-size: 7vw;
    }
    .profile_bio_div{
        margin-top: -10px;
        font-size: 3vw;
    }
    .user_points{
        font-size: 3vw;
        margin-top: 10px;
    }
    .img_upload_div {
        height: 120px;
        width: 45%;
    }
    .profile_img_box{
        width: 150px;
    }   
    .profile_img{
        width: 70px;
        height: 70px;
    }
    .profile_pic{
        width: 70px;
        height: 70px;
    }
    .paper_plane{
        height: 3vw;
    }
    .submit_pic { 
        margin: 0;
        float: right;
        width: 50%;
    } 
    .msg_head{
        float: left;
        font-size: 1rem;
    }
    .user_profile_div{
        width: 80%;
        height: 50vh;
    }
    .user_profile_div h5 {
        font-size: 1rem;
    }
    .button_div{
        height: auto;
        display:inline-block;
        justify-content: center;
        align-items: center;
        /* border: 1px solid black; */
    }
    .btn_below_link{
        /* border: 1px solid black; */
        width:90%;
        margin-left: 10%;
    }
    .btn_below_profile { 
        width: 90%;
        font-size: 4vw;
        /* border: 1px solid black; */
    }
    .profile_full_page{
        margin-top: -30px;
    }
    
}

@media only screen and (max-width: 350px) {
    .user_profile_div{
        width: 77%;
    }
}