.ann_pop_main_page{
    position: absolute;
    z-index: 1;
    right: 300px;
    top: 100px;
    display: flex;
    flex-direction: row;
    color: white !important;
    cursor: pointer !important;
  }
  .ann_pop_main_content{
    background-color: #9A4CDF;
    width: 300px;
    min-height: 40px;
    height: auto;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #main_page_pop_icon{
    padding: 10px;
  }
  #main_page_pop_heading{
    color: yellow;
    font-weight: 700;
    font-size: 18px !important;
    text-decoration: underline;
    background-color: #9A4CDF;
  }
  #main_page_pop_text{
    background-color: #9A4CDF;
    font-size: 15px !important;
    color: black!important;
    font-weight: bold !important;
  }

  #link_announcement{
    text-decoration: none;
  }
  
@media screen and (max-width: 1280px){
  .ann_pop_main_page{
    display: none;
  }
}
@media screen and (max-width: 768px){
  .ann_pop_main_page{
    display: none;
  }
}