.questionaire_modal{
  display: flex;
  position: absolute;
  justify-content: center;
  width: 45%!important;
  background-color: #fff;
  border-radius: 12px;
  outline: 0; 
}

.know_your_score{
  width: 50%!important;
}

.cropper_modal{
  position: absolute;
  width: 50%;
  background-color: #fff;
  border: #000 0.5px solid;
  border-radius: 4px;
}

.cropper_btn_container{
  flex: 1;
}

.cropper_btns{
  width: 40%;
}

.Question_box {
  /* margin-top: 20%; */
  width: 100%;
  padding: 4%;
  height: fit-content;
}

.question_containers{
  padding: 2%;
}
.questionaire_questions_heading{
  margin-bottom: 20px;
}
.questionaire_selector{
  color : black; 
}

.Questionaire_heading {
  font-weight: 1000;
  font-size: 2.5rem;
  margin: auto !important;
  padding-top: 20px;
  font-family: "Spartan", sans-serif !important;
}

.Questions_box {
  margin-top: 15px !important;
}

.questionaire_container {
  margin-top: 4%;
  padding: 2%;
  display: flex;
  flex-direction: column;
}

.view_sample_btn_contianer {
  display: flex;
  justify-content: flex-end;
}

.view_btn {
  width: 20%;
}

/* .test_apply_btn{
  width : 150px; 
  display: flex; 
  flex-direction: row;
} */
.question_container {
  padding: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer_input_box {
  font-size: 2.5rem;
  font-family: "Open Sans", sans-serif !important;
  outline: none;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border-bottom: 2px solid black;
  text-align: center;
  /* margin-left: 2%; */
  /* margin-bottom: 2%; */
  /* border-bottom: 2px solid black; */
}

.answer_input_textarea {
  font-size: 2.5rem;
  font-family: "Open Sans", sans-serif !important;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border-bottom: 2px solid black;
  text-align: center;
  background-size: 100% 4ch;
  resize: none;
  margin-top: 10px;
    /*background-color: yellow;*/
  width: 120vh;
}

.question_lines {
  font-size: 3rem;
  font-family: "Open Sans", sans-serif !important;
  /*background-color: purple;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.next_prev_button_container {
  display: flex; 
  /* justify-content: space-around; */
  width: 100vw;
  height: 100px;
  /*background-color: red;*/
}

.left_button_box {
  display: flex;
  flex: 6;
  justify-content: center;
  /*background-color: blue;*/
}

.right_button_box {
  display: flex;
  flex: 6;
  justify-content: center;
  /*background-color: yellow;*/
}

.step_btn {
  width: 20%;
}

.sample_box{
  margin: 1%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 1%;
}

.sample_para{
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif!important;
}

.sample_para_answers{
  color: blue
}

.sample_box_icons{
  /* background-color: red; */
  display: flex;
  padding: 0.5%;
}

.sample_icons{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 1%;
}

.show_comments_box{
  display: flex;
  justify-content: center;
}

.comments_container{
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
}

.comment_container{
  background-color: #eaf1f7;
  padding: 1%;
  padding-top: 0;
  padding-bottom: 0;
  margin: 1%;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

.comment_name{
  font-size: 1.3rem;
  margin: 0;
  padding-top: 1%;
  padding-bottom: 0.5%;
}

.comment_content{
  font-size: 1.3rem;
  margin: 0;
  padding-top: 0.5%;
  padding-bottom: 1%;
}

.sample-ideation{
  width: 100%;
  /* height: auto; */
}
@media only screen and (max-width: 1100px){
  .know_your_score{
    width: 80%!important;
  }
  .cropper_btns{
    width: 50%;
  }
  .questionaire_modal{
    width: 65%!important;
    padding: 4%;
  }
}
@media only screen and (max-width: 768px) {
  .view_sample_btn_contianer {
      justify-content: center;
    }
    .view_btn {
      width: 80%;
    }
    .question_container {
      padding-left: 0;
    }
    .question_lines {
      font-size: 2.5rem;
      display: flex !important;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .answer_input_box {
      font-size: 1.2rem;
      width: 90% !important;
    }
    .step_btn {
      width: 80%;
    }
    .cropper_modal{
      width: 80%;
    }
    .cropper_btns{
      width: 60%;
      height: 25px;
      font-size: 0.8rem;
    }
    .questionaire_modal{
      width: 80%!important;
      padding: 4%;
    }
}
@media only screen and (max-width: 698px) {
  .questionaire_modal{
    width: 80%!important;
    padding: 4%;
  }
}

@media only screen and (max-width: 420px) {
  
  .questionaire_modal{
    width: 80%!important;
    padding: 5%;
  }
  .view_sample_btn_contianer {
    justify-content: center;
  }
  .view_btn {
    width: 80%;
  }
  .question_container {
    padding-left: 0;
  }
  .question_lines {
    font-size: 2.2rem;
    display: flex !important;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .answer_input_box {
    font-size: 1.2rem;
    width: 90% !important;
  }
  .answer_input_textarea{
    font-size: 1.2rem;
    width: 90% !important;
    /* height: 15em; */
    background: none;
  }
  .step_btn {
    width: 80%;
  }
  .sample_box{
    padding: 5%;
  }
  .sample_para{
    font-size: 1rem;
  }
  .comment_content{
    font-size: 0.8rem;
  }
  .sample_icons{
    width: 25px;
    height: 25px;
  }
}
@media  only screen and (max-width: 360px) {
  .cropper_btns{
    margin-top: 15%;
  }
}

