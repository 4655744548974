
  
  .para_test{
    position: relative;
    padding: 20px 20px 20px 65px;
    background: var(--my-css-var);
    border-radius: 15px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    min-height: 110px;
    word-wrap: normal!important;
    color: rgb(0, 0, 0);
  }

  @media only screen and (max-width: 420px){
    .para_test{
      font-size: 1rem!important;
      min-height: 90px!important;
      margin-bottom: 15px!important;
    }
    .cont1{
      padding-right: inherit!important;
      margin-bottom: 3rem!important;
    }
    .wrapper{
      margin-bottom: 30px!important;
    }
  }
  
  /* Speach bouble Quote */
  .para_test:before{
    content: "\201C"; /*Unicode for Left Double Quote*/
    
    /*Font*/
    font-family: 'Open Sans', sans-serif;
    font-size: 60px;
    font-weight: bold;
    color: black;
    
    /*Positioning*/
    position: absolute;
    left: 20px;
    top: 5px;
  }
  
  /* Speech bouble triangle */
  .para_test:after{
    content: ' ';
    border: 10px solid;
    border-color: var(--my-css-var) transparent transparent var(--my-css-var);
    position: absolute;
    bottom: -20px;
    left: 50px;
  }

  .test_img{
      width: 50px;
      height: 50px;
      border: 2px solid black;
      border-radius: 999px;
  }
  
  .wrapper{
    margin-bottom: 50px;
  }
  .wrapper img{
    float: left;
    margin-right: 20px;
  }
  .wrapper h3{
    color: var(--my-css-var);
    margin: auto!important; 
    font-family: 'Open Sans', sans-serif;
  }
  .wrapper small{
    display: block;
    color: black;
    font-weight: 700;
    margin-top: -10px;
    font-family: 'Open Sans', sans-serif;
    margin: auto!important; 
  }

  .grid_testimonials{
      padding: 20px;
  }

  .grid_test{
      padding: 10px!important;
  }

.hash_link{
    text-decoration: none!important;
}
  /* .wrapper ul{
    list-style: none;
  }
  .wrapper ul li{
    display: inline;
    font-size: 1.3em;
  }
  .wrapper ul li:nth-child(-n+4){
    color: var(--my-css-var);
  } */