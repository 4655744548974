.partner_ship {
  width: 100%;
  overflow: hidden;
}

.Box_partnership {
  height: 100% !important;
  margin: auto !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem;
  /* padding-left: 20px!important; */
  /* background-color: red; */
}

.organise_form {
  width: 100%;
  /*background-color: blue;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Organise_partnership {
  height: 31rem !important;
  width: 90% !important;
  scroll-margin-top: 100px;
  margin: auto !important;
  margin-bottom: 25px !important;
  padding-left: 3% !important;
  /*background-color: blue;*/
}
.partner_heading {
  font-weight: 1000;
  font-size: 5rem;
  margin-bottom: auto !important;
  font-family: "Spartan", sans-serif !important;
}
.partner_heading_box{
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  /*background-color: red;*/
  /*color: white;*/
}

.apply_form {
  height: 100% !important;
  /* background-color: red; */
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 630px) {
  .partner_heading {
    font-size: 4rem !important ;
  }
  .partner_para,
  .partner_para2 {
    font-size: 1.5rem !important;
  }
}
@media only screen and (max-width: 550px) {
  .subscriptions_container {
    padding-bottom: 15%;
  }
  .apply_form {
    padding-bottom: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*background-color: blue;*/
  }
  .partner_heading {
    font-size: 3rem !important ;
  }
  .partner_para,
  .partner_para2 {
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 420px) {
  .Organise_partnership {
    scroll-margin-top: 90px !important;
  }
  .organise_form {
    width: 100%;
    /*background-color: blue;*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .apply_form {
    scroll-margin-top: 90px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*background-color: chocolate;*/
  }
  .partner_heading {
    font-size: 2.5rem !important ;
  }
  .partner_para,
  .partner_para2 {
    font-size: 0.9rem !important;
  }
  .action_btn {
    width: 43% !important;
    font-size: 0.7rem !important;
    height: 50px !important;
    color: black !important;
    font-family: "Spartan", sans-serif !important;
  }
  .form_heading {
    font-size: 1.4rem ;
    /*background-color: yellow;*/
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file_input {
    font-size: 0.7rem !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600;
  }
  .arrow_detail {
    margin-top: 10px !important;
  }
  .subscriptions_container {
    padding-bottom: 25%;
  }
}

@media only screen and (max-width: 420px) {
  .form_heading {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .partner_heading {
    font-weight: 10;
    font-size: 30px;
    margin-bottom: auto !important;
    font-family: "Spartan", sans-serif !important;
    /*background-color: yellow;*/
  }
  .partner_heading_box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    /*background-color: red;*/
    /*color: white;*/
  }
  .action_input1::placeholder{
    font-size: 12px;
    text-align: left;
    margin-left: 0px;
    /*background-color: blue;*/
    padding-left: 0px;
  }
}

.form_heading {
  font-weight: 1000;
  font-size: 4rem;
  margin: auto !important;
  font-family: "Spartan", sans-serif !important;
}
#more {
  display: none;
}
#myBtn {
  width: 100px;
  height: 30px;
  border: none;
  background-color: inherit;
  font-size: 1rem;
  margin: 0;
  cursor: pointer;
  outline: none;
}
.partner_para {
  margin: auto;
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif !important;
  float: left;
  margin-top: 7px;
  margin-bottom: 5px;
}
.partner_para2 {
  margin: auto;
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif !important;
  float: right;
  margin-top: 7px;
  margin-bottom: 5px;
}
.partnership_btn {
  display: flex;
  flex-direction: column;
}

.action_btn {
  width: "40%";
  height: 70px;
  font-size: 1.8rem;
  font-weight: bold;
  background-color: rgb(82, 239, 218);
  color: white;
  margin: 0;
  margin-right: 15px;
  font-family: "Spartan", sans-serif !important;
  border: none !important;
  display: inline;
  cursor: pointer;
}

.partnership_input {
  /* width: 200px; */
  /* margin: 0 auto; */
  /* display: inline; */
  /*background-color: yellow;*/
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.action_input {
  width: 95%;
  height: 40px;
  padding: 0.3%;
  padding-left: 1.5%;
  font-size: 1rem;
  font-weight: bold;
  background-color: #dddddd !important;
  margin: 5px;
  margin-bottom: 0px;
  font-family: "Open Sans", sans-serif !important;
  border: none !important;
  /* display: inline; */
}

.action_input_container {
  width: 100%;
  /*background-color: blue;*/
}

.action_input1 {
  width: 95%;
  height: 40px;
  padding: 0.3%;
  padding-left: 1.5%;
  font-size: 1rem;
  font-weight: bold;
  background-color: #dddddd !important;
  margin: 5px;
  margin-bottom: 0px;
  font-family: "Open Sans", sans-serif !important;
  border: none !important;
}

.action_input1::placeholder{
  text-align: left;
  margin-left: 0px;
  /*background-color: blue;*/
  padding-left: 0px;
}

.error-code {
  color: red;
  margin-top: 0px;
  padding-left: 1%;
}

.file_input {
  margin-left: 5px;
  font-family: "Open Sans", sans-serif !important;
}

.exp_btn {
  width: 75%;
  padding: 2%;
  height: 100%;
  font-size: 1.5rem;
  background-color: rgb(82, 239, 218);
  color: white;
  margin: 0;
  font-family: "Spartan", sans-serif !important;
  border: none !important;
  cursor: pointer;
}

.exp_btn_test {
  width: 75%;
  height: 100%;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  border: none;
  font-weight: 900;
  font-size: 1.2rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
  border: none !important;
  padding: 2%;
}

.action_btn1 {
  width: 35%;
  height: 40px;
  font-size: 1.2rem;
  font-weight: 900;
  color: #000;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  margin: 5px;
  font-family: "Spartan", sans-serif !important;
  border: none !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.hash_link {
  text-decoration: none !important;
}

.arrow_detail {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 5px;
  cursor: pointer;
}

.arrow_detail_btn {
  text-decoration: none !important;
  color: black !important;
  font-weight: bold;
  background-color: rgb(82, 239, 218);
  border-radius: 999px;
  width: 30px !important;
  height: 26px !important;
  text-align: center !important;
  padding-top: 3px !important;
}

.action_btn:active {
  color: rgb(0, 0, 0);
  background-color: rgb(5, 252, 219);
}

.action_btn1:active {
  color: black;
  background-color: rgb(5, 252, 219);
}

.subscriptions_container {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  padding-top: 10px;
  justify-content: center;
  /* background-color: blue; */
}

.common {
  width: 300px;
  /* padding-right: 60px; */
  padding-left: 30px;
  padding-right: 30px;
  /* margin-left: 2px; */
  box-sizing: border-box;
  margin-bottom: 5%;
}

.para_sub {
  font-size: 0.8rem;
  text-align: center;
  font-family: "Open Sans";
  margin: 0;
  padding: 10%;
}

.head_sub {
  font-family: "Spartan";
  background-color: #8dffcc;
  padding: 5%;
}

.mid_sub {
  background-color: #52efda;
}

.bottom_sub {
  background-color: #3e2c41;
  color: white;
}

.sub_head {
  margin: 0;
  padding: 0;
}

.sub_heading_btn {
  width: 35%;
  height: 80%;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  background-color: #52efda;
  margin: 5px;
  font-family: "Spartan", sans-serif !important;
  /* margin-right: 15px; */
  border: none !important;
  cursor: pointer;
  position: relative;
  /* margin-left: 15%; */
}

.sub_heading {
  font-weight: 1000;
  font-size: 2.5rem;
  margin: auto !important;
  font-family: "Spartan", sans-serif !important;
  position: relative;
  display: inline-block;
}

.sub_btn {
  width: 50%;
  height: 30px;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  background-color: rgb(82, 239, 218);
  margin: 5px;
  font-family: "Spartan", sans-serif !important;
  margin-right: 15px;
  border: none !important;
  cursor: pointer;
}

.sub_para {
  font-size: 1.2rem;
}

.live_project_container {
  background-color: #fff;
  width: 100%;
  overflow: hidden;
}

.live_project_inner_container {
  width: 90%;
  margin: auto;
  margin-top: 35px;
  margin-bottom: 10%;
}

.live_project_header {
  padding-top: 20px;
  padding-bottom: 10px;
}

.live_project_sub_header {
  padding-bottom: 25px;
}

.live_project_apply_box {
  background-color: #daf9fb;
  padding: 1%;
  margin-bottom: 2%;
}

.links {
  color: #487dd8;
  margin: 0px;
  font-size: 1.5rem;
  font-weight: bold;
}

.startup_name_box {
  display: flex;
  align-items: center;
  height: auto;
}

.apply_button_box {
  display: flex;
}

.startup_description {
  font-size: 1.1rem;
  font-family: "Open Sans";
  margin: auto;
  flex: 5;
  margin-top: 7px;
}

.apply_btn {
  height: 30px;
  width: 90%;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  border: none;
  font-weight: 900;
  font-size: 1.2rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
  flex: 1;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 40px;
  /* margin: 20px 10px; */
}

.position_box {
  display: flex;
  flex-direction: column;
}

.position_box_inner {
  display: flex;
}

.position_header {
  font-size: 1.2rem;
}
@media screen and (min-width: 813px) {
  .action_btn1 {
    width: 50%;
  }
}
@media only screen and (max-width: 812px) {
  .action_input {
    font-size: 1rem;
  }
  .action_input1 {
    font-size: 1rem;
  }
  .action_btn1 {
    font-size: 1rem;
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .action_input {
    font-size: 0.8rem;
  }
  .action_input1 {
    font-size: 0.8rem;
  }
  .live_project_container {
    padding-bottom: 15%;
  }
  .error-code {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 520px) {
  .form_heading {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 420px) {
  .partnership_input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*background-color: yellow;*/
    width: 100%;
  }
  .form_heading {
    font-size: 1.3rem;
    /*background-color: yellow;*/
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .action_input {
    font-size: 0.8rem;
    width: 95%;
  }
  .action_input_container {
    width: 100%;
  }
  .action_input1 {
    font-size: 0.8rem;
    width: 95%;
  }
  .startup_description {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 360px) {
  .action_input {
    font-size: 0.8rem;
    width: 95%;
    margin-bottom: 10px;
  }
  .action_input1 {
    font-size: 0.8rem;
    width: 95%;
    margin-bottom: 10px;
  }
  .action_btn1 {
    font-size: 0.8rem;
    font-weight: lighter;
  }
  .live_project_container {
    padding-bottom: 25%;
  }
  .live_project_header {
    padding-top: 0;
  }
  .startup_description {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 320px) {
  .sub_heading_btn {
    width: 45%;
  }
  .startup_description {
    font-size: 0.75rem;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 280px) {
  .form_heading {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
