.ideation_container{
    overflow: hidden;
    margin-top: 4%!important;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 0;
}

.start_btn_container{
    display: flex;
    width: 100%;
    padding: 2%;
}

.buttons{
    text-align: center;
    cursor: pointer!important;
    width: 50%;
}

.error{
    color: red;
}

.Box{
    margin-top: 4rem;
}

.left_box{
    width: 100%!important;
    height: 100%;
}

.right_box{
    width: 100%!important;
    height: 100%;
    display: flex;
}

.right_box_button{
    /* border: 1px solid black; */
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    flex: 1;
    padding-top: 2%;
    padding-bottom: 2%;
}

.iconStyle{
    padding: 15%;
    border-radius: 100%;
    border: 0.5px solid black;
    font-size: 54px;
}

.header_big{
    font-weight: 1000;
    font-size: 7rem;
    margin: auto!important;
    font-family: 'Spartan', sans-serif!important;
    letter-spacing: -0.2rem;
}

.right_button_header_big{
    font-size: 1.5rem;
    margin: auto!important;
    padding-top: 2%;
    font-family: 'Spartan', sans-serif!important;
}

.right_button_header_small{
    font-weight: 'bold';
    font-size: 0.8rem;
    margin: auto!important;
    font-family: 'Open Sans', sans-serif!important;
}

.progress_bar_container{
    display: flex;
    justify-content: center;
}

.progress_bar{
    width: 80%;
}

.number_of_users{
    margin: auto;
    font-family: 'Spartan', sans-serif!important;
}
.Alert_type{
    width: 90%!important;
    margin: auto!important;
}

.Alert_type{
    margin-bottom: 0.5rem!important;
}

.comment_input{
    height: 5em;
    resize: none;
}

@media only screen and (max-width: 768px){
    .header_big{
        font-size: 6rem;
    }
    .header_small{
        font-size: 2rem;
    }
    .Signup_Form_container{
        padding-bottom: 25%;
    }
    .start_btn_container{
        justify-content: center;
    }
}

@media only screen and (max-width: 540px){
    .Signup_Form_container{
        padding-bottom: 35%;
    }
    .signup_form{
        width: 85%;
        margin-top: 5%;
    }
    .header_big{
        font-size: 5rem;
    }
}


@media only screen and (max-width: 420px){
    .ideation_container{
        padding-bottom: 25%;
    }
    .start_btn_container{
        justify-content: center;
    }
    .header_big{
        font-size: 4rem;
    }
    .header_small{
        font-size: 1.2rem;
    }
    .progress_bar{
        width: 65%;
    }
    .Signup_Form_container{
        padding-bottom: 40%;
    }
    .signup_form{
        width: 100%;
        margin-top: 5%;
    }
    .Box_type{
        width: 25rem!important;
    }
    .Alert_type{
        width: 26rem!important;
    }
    .iconStyle{
        padding: 5%;
    }
    .right_box{
        flex-direction: column;
    }
    .right_button_header_big{
        font-size: 1rem;
    }
}

@media only screen and (max-width: 320px){
    .header_big{
        font-size: 3.5rem;
    }
    .header_small{
        font-size: 1.1rem;
    }
    .Signup_Form_container{
        padding-bottom: 45%;
    }
}