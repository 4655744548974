body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
input {
  padding: 7px;
}
textarea {
  padding: 7px;
}
::-webkit-input-placeholder {
  -webkit-text-security: none;
  color: rgb(117, 117, 117);
  direction: inherit !important;
  pointer-events: none !important;
  text-orientation: inherit !important;
  writing-mode: inherit !important;
}
.a-box {
  padding: 15px;
  margin-top: 5%;
  margin-left: 35%;
  display: block;
  height: 470px;
  width: 450px;
  border-radius: 4px;
  border: 1px #ddd solid;
  background-color: #fff;
}
label {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.test_apply_btn {
  display: inline-block;
  width: 100%;
  height: 55px;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  border: none;
  font-weight: 900;
  font-size: 1rem;
  font-family: "Spartan", sans-serif !important;
  font-stretch: ultra-expanded;
  cursor: pointer;
  padding: 0px 20px;
  margin-top: 10px;
}
.date_event {
  width: 50% !important;
  margin-bottom: 10px;
}
.form-group {
  margin-bottom: 10px;
}
#date_event {
  margin-top: 10px !important;
}
.btn-primary {
  margin-top: 15px;
}
.test_input {
  margin-top: 10px;
  width: 400px;
}
