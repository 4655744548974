.Login_Form {
  /* margin-top: 5rem;
    margin-bottom: 2rem;
    font-family: 'Open Sans', sans-serif!important; */
  
  padding-top: 3% !important;
  padding-left: 5%;
  padding-right: 5%; 
  padding-bottom: 1rem;
  background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
  overflow: hidden;
  height: 80vh;
}

.left_box {
  width: 100% !important;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 1rem;
  font-size: 4rem;
  padding: 3rem;
  color: white;
  font-family: "Spartan", sans-serif !important;
}

.titleLogin{
  animation-name:moveRight;
  animation-duration: 0.7s;
  color: #6A58C6;
}

.titleTwoLogin{
  animation-name: moveLeft;
  animation-duration: 1s;
  color: #6A58C6;
}

.titleThreeLogin{
  animation-name: moveRight;
  animation-duration: 1.3s;
  color: #6A58C6;
  margin-bottom: 10px;
}

.right_box {
  width: 100%; 
}
.login_form {
  background: linear-gradient(to top left, #ffffffff,#ffffff33);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  width: 60%;
  margin: auto;
  margin-top: 5%;
  box-shadow: 2px 2px 5px #2773a5 ;
  z-index: 2;
  padding: 5%;
}

.login_form > h3 {
  text-align: center;
}

.auth_button {
  cursor: pointer !important;
  width: 100%;
}

.auth_button .button_login{
  background-color: #3498db;
  color: white;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;  
  border: none;
  cursor: pointer;
  margin-top: 0rem;
  
}
.auth_button .button_login:hover{
background-color: #2773a5;
}

.error {
  color: red;
}

.Box {
  margin-top: 4rem;
}

.not_member {
  font-size: 0.8rem !important;
}

.textLogin{
  font-size:40px;
  color: #ffffffff;
  margin-bottom: 20px;
}


@keyframes moveRight{
  0%{
    opacity:0;
    transform:translateX(100px);
  }

  100%{
    opacity:1;
    transform:translate((0));
  }
}
@keyframes moveLeft{
  0%{
    opacity:0;
    transform:translateX(-150px);
  }

  100%{
    opacity:1;
    transform:translate((0));
  }
}
@keyframes moveUp{
  0%{
    opacity:0;
    transform:translateY(50px);
  }

  100%{
    opacity:1;
    transform:translate((0));
  }
}

@media only screen and (max-width: 1280px) {
  .Login_Form {
    padding-bottom: 20%;
    height: 100%;
  }
  .login_form {
    width: 90%;
  }
  .textLogin{
    display: flex;
    width: 90vw;
    font-size: 2rem;
    margin-left: -40px; 
  }
  .main-heading{
    font-size: 3rem;
    margin-left: -40px ;;
  }
  .left_box{
     padding-top: 1rem !important;
  }
}
@media screen and (max-width:700px){
  .Login_Form {
    padding-bottom: 20%;
    height: 100%;
  }
  .login_form {
    width: 90%;
  }
  .textLogin{
    display: flex;
    width: 90vw;
    font-size: 1rem;
    margin-left: -40px ;
  }
  .main-heading{
    font-size: 2rem;
    margin-left: -40px ;;
  }
  .left_box{
     padding-top: 1rem !important;
  }
}
@media only screen and (max-width: 420px) {
  .Login_Form {
    padding-bottom: 25%;
    height: 100%;
  }
  .login_form {
    width: 90%;

  }
  .textLogin{
    display: flex;
    width: 90vw;
    margin-left: -40px !important;
    font-size: 1rem;
  }
  .main-heading{
    font-size: 2rem;
  }
}

@media only screen and (max-width: 320px) {
  .Login_Form {
    padding-bottom: 30%;
  }
  .login_form {
    width: 90%;
  }
  .main-heading{
    font-size: 1.5rem;

  }
  .left_box{
    margin-left: -1rem !important;
    margin-bottom: auto;

 }
}


/* The code is very responsive and I did not write the confusing classnames */
