

.buttons_grid{
    padding: inherit!important;
    text-decoration: none;
}

.bottom_card{
    /* padding: inherit!important; */
    padding-bottom: 5px!important;
    padding-top: 35px!important;
    padding-left: 5px!important;
    padding-right: 5px!important;
}

.react-3d-carousel{
    margin-top: 15px;
    height: 400px!important;
    
}

.event_btn{
    width: 100%;
    height: 80px;
    border-color: inherit!important;
    border: none!important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    text-decoration: none;
}

.event_heading{
    font-weight: 600!important;
    font-family:'Courier New', Courier, monospace!important;
    color: whitesmoke!important;
    margin-bottom: inherit!important;
}

.event_img{
    width: 640px!important;
    height: 250px!important;
}

.iiz__img{
    width: 100%!important;
    height: 250px!important;
}

@media only screen and (max-width: 420px){
    .event_btn{
        width: 100%;
        height: 60px;
        border-color: inherit!important;
        border: none!important;
        cursor: pointer;
        font-size: 0.5rem!important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        text-decoration: none;
    }
    
    .event_heading{
        font-weight: 600!important;
        font-family:'Courier New', Courier, monospace!important;
        color: whitesmoke!important;
        font-size: 1rem!important;
    }

    .event_img{
        width: 283px!important;
        height: 151px!important;
    }

    .iiz__img{
        width: 100%!important;
        height: 150px!important;
    }
    .iiz__close--visible{
        opacity: .5!important;
    }
    .iiz__hint{
        opacity: .5!important;
    }

}

.fa{
    color: black;
}

.react-3d-carousel .slider-container .slider-right div{
    border: 2px solid grey!important;
    background: white;
    width: 20px!important;
    border-radius: 999px;
    text-align: center!important;
}

.react-3d-carousel .slider-container .slider-left div{
    border: 2px solid grey!important;
    background: white;
    width: 20px!important;
    border-radius: 999px;
    text-align: center!important;
}

.upper_card{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.react-3d-carousel .slider-container .slider-content{
    width: inherit!important;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content{
    display: flex!important;
    box-shadow: inherit!important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content .makeStyles-root-5{
    background-color: #337ab7!important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content .makeStyles-root-8{
    background-color: #337ab7!important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.active .slider-single-content .middle_card{
    background-color: #337ab7!important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content{
    opacity: .9!important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content{
    opacity: .9!important;
}
